import React from 'react'
import styled from 'styled-components'

import Paragraph from 'components/styled/Paragraph'

export default ({ image, name, bio, className }) => (
  <Container className={className}>
    <Image image={image} />
    <Bio>
      <Name>{name}</Name>
      <Paragraph>{bio}</Paragraph>
    </Bio>
  </Container>
)

const Container = styled.div`
  padding: 30px 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: ${(props) => props.theme.desktop}px) {
    display: block;
    padding: 30px 0;
  }
`
const Name = styled.h2`
  margin: 40px 0 25px;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 22pt;
  line-height: 26pt;
  letter-spacing: 0;
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    text-align: center;
  }
`
const Bio = styled.div`
  margin: 0 50px;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    text-align: center;
    margin: 0 40px;
  }
`
const Image = styled.div`
  margin: 0 auto;
  background-image: url(${(props) => props.image});
  background-size: cover;
  height: 200px;
  width: 200px;
  min-height: 300px;
  min-width: 300px;
  border-radius: 150px;
`
