import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Header from 'components/HeaderSection'
import Owners from './Owners'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, header, owners } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <Header
        title={header.title}
        content={header.description}
        src={header.image.childImageSharp.gatsbyImageData.images.fallback.src}
        color="pink"
      />
      <Owners owners={owners} />
    </Fragment>
  )
}

export const query = graphql`
  query AboutPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        owners {
          name
          image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          bio
        }
      }
    }
  }
`
