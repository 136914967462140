import React from 'react'

import Owner from './Owner'

export default ({ owners }) => (
  <div>
    {owners.map((owner) => (
      <Owner
        name={owner.name}
        image={owner.image.childImageSharp.gatsbyImageData.images.fallback.src}
        bio={owner.bio}
      />
    ))}
  </div>
)
