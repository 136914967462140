import styled from 'styled-components'

const Paragraph = styled.div`
  margin: 0 0 15px;
  padding: 0;
  font-family: ${props => props.theme.bodyFont};
  font-size: 12pt;
  line-height: 20pt;
  color: ${props => props.theme.secondaryColor};

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 0 0 15px;
    text-align: center;
  }
`
export default Paragraph
